import SearchFilter from "@/components/SearchFilter";
import { FullInput } from "@/components/Utils";
import ChannelEmptyState from "@/components/WorkspaceChannelList/ChannelEmptyState";
import ChannelTypes from "@/components/WorkspaceChannelList/ChannelTypes";
import NewWorkspaceChannelItem from "@/components/WorkspaceChannelList/WorkspaceChannelLinkItem";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import Locator from "@/locator";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import {
  ChannelList,
  ChannelOrderType,
  ChannelTypeType,
  fetchChannelList,
} from "@/models/commonQueries";
import CancelRounded from "@mui/icons-material/CancelRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Tooltip } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext, useState } from "react";
import { VList } from "virtua";

export default function WorkspaceChannelListContainer({
  notificationsBanner,
}: { notificationsBanner: boolean }) {
  const { userReadOnlyMode } = useContext(UxContext);
  const {
    isWorkspaceLimitedMember,
    currentWorkspaceId,
    myCurrentWorkspaceMembership,
  } = useContext(WorkspaceContext);
  const { currentFeedId } = useContext(CurrentFeedContext);
  const { workspaceMembershipId } = useContext(LiveQueryContext);
  const limitedMember = isWorkspaceLimitedMember();
  const feedId = currentFeedId;
  const { myAccount } = useContext(MyAccountContext);

  const [channelOrder, saveChannelOrder] = useLocalStorage(
    `${currentWorkspaceId}-channel-sort-order`,
    "driver-activity" as ChannelOrderType,
  );

  const [channelType, saveChannelType] = useLocalStorage(
    `${currentWorkspaceId}-channel-sort-type`,
    "my-channels" as ChannelTypeType,
  );

  const [searchValue, setSearchValue] = useState<string>("");

  const tempOverRide =
    searchValue?.length > 0 && !limitedMember ? "all-channels" : null;

  const dynamicChannelType =
    tempOverRide !== null ? tempOverRide : (channelType as ChannelTypeType);

  const { rows: channels } = useDrizzleSelect(
    fetchChannelList({
      channelOrder,
      channelType: dynamicChannelType,
      workspaceId: currentWorkspaceId,
      myCurrentWorkspaceMembership,
      searchValue,
      myAccount,
    }),
  ) as { rows: ChannelList[] };

  const unreadCount = channels?.filter(
    (channel) => channel.unread && channel.joined,
  ).length;

  const [searchInputFullWidth, setSearchInputFullWidth] =
    useState<boolean>(false);

  const handleInputFocus = (value: boolean) => {
    if (value === true && !searchInputFullWidth) {
      setSearchInputFullWidth(() => true);
    } else if (value === false && searchValue.length === 0) {
      setSearchInputFullWidth(() => false);
    }
  };

  return (
    <Box
      className={`
        workspace-channel-list-container
        ${notificationsBanner ? "has-banner" : ""}
        ${userReadOnlyMode ? "read-only-mode" : ""}
      `}
    >
      <Box
        className={`
            channel-search-container
            ${searchInputFullWidth ? "active" : ""}
        `}
      >
        <Box
          className={`channel-search ${limitedMember ? "limited-member" : ""}`}
        >
          <FullInput
            onFocus={() => handleInputFocus(true)}
            onBlur={() => handleInputFocus(false)}
            value={searchValue}
            inputProps={{
              placeholder: "Search",
            }}
            formControlProps={{
              sx: {
                "& .MuiInputBase-input": {
                  paddingLeft: "1rem !important",
                },
              },
            }}
            callback={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Box className="channel-search-clear-button">
            {searchValue?.length ? (
              <button
                type="button"
                aria-label={Locator.workspaceNav.channels.find.clear}
                onClick={() => {
                  setSearchValue("");
                  setSearchInputFullWidth(() => false);
                }}
              >
                <CancelRounded />
              </button>
            ) : (
              <div>
                <SearchIcon
                  sx={{ color: searchValue?.length > 0 ? "#fff" : "#ccc" }}
                />
              </div>
            )}
          </Box>
        </Box>
        <Box className={`flex channel-options`}>
          <Box className="channel-options-button"></Box>
          <Box className="channel-options-button">
            <SearchFilter
              channelOrder={channelOrder}
              changeChannelSortOrder={(order: ChannelOrderType) =>
                saveChannelOrder(order)
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 2, px: 1 }}>
        <ChannelTypes
          tempOverRide={tempOverRide}
          limitedMember={limitedMember}
          unreadCount={unreadCount}
          changeChannelType={(channelType: ChannelTypeType) => {
            saveChannelType(() => channelType);
            if (channelType !== "all-channels") {
              setSearchValue("");
            }
          }}
          activeChannelType={channelType}
        />
      </Box>

      <VList
        className="workspace-channel-list-items"
        aria-label={Locator.workspaceNav.channels.list.container}
        itemSize={40}
        shift={false}
        overscan={0}
        count={channels?.length}
        style={{
          height: notificationsBanner
            ? "calc(100vh - 435px)"
            : "calc(100vh - 345px)",
        }}
      >
        {channels?.length > 0 ? (
          <>
            {channels.map((channel) => (
              <NewWorkspaceChannelItem
                limitedMember={limitedMember}
                readOnlyMode={userReadOnlyMode}
                key={channel.id}
                active={channel.id === feedId}
                channel={channel}
                workspaceMembershipId={workspaceMembershipId}
              />
            ))}
          </>
        ) : (
          <ChannelEmptyState
            limitedMember={limitedMember}
            hasSearch={searchValue?.length > 0}
            channelType={channelType}
            changeChannelType={(channelType: ChannelTypeType) => {
              saveChannelType(channelType);
            }}
          />
        )}
      </VList>
    </Box>
  );
}
