import FeedItem from "@/components/FeedItem";
import FeedItemHeader from "@/components/FeedItemHeader";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TelemetryContext, actions } from "@/models/TelemetryProvider";
import { downloadFeedItemContent } from "@/models/actions/initialFeedLoad";
import { ArrowDownward } from "@mui/icons-material";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { id } from "date-fns/locale";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { VList } from "virtua";

export default function FeedVlist({
  setRenderToggle,
}: { setRenderToggle: Dispatch<SetStateAction<string>> }) {
  const { feedMap, vListRef, currentFeed } = useContext(FeedContext);
  const theme = useTheme();
  const { trackAction, finishAction } = useContext(TelemetryContext);
  const { client } = useContext(AppContext);
  const { preferredLanguage } = useContext(DataContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const wls = window?.location?.search; // window location search
  const feedItemId = new URLSearchParams(wls).get("feedItemId") ?? null;
  const { hash } = useLocation();
  const hashValue = hash?.split("#")?.[1] ?? null;
  const hasContentId = hashValue || feedItemId;
  const [activeFeedItemIndex, setActiveFeedItemIndex] = useState<number>(-1);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [range, setRange] = useState<number[]>([0, 0]);
  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);
  const [allowJumpButton, setAllowJumpButton] = useState<boolean>(false);
  const [preventScroll, setPreventScroll] = useState<boolean>(false);
  const initialContentLoad = useRef<boolean>(true);
  const countThreshold = 5;
  const feedId = currentFeed?.id;
  const workspaceId = currentWorkspaceId;

  const removeHash = () => {
    if (
      window.location.hash ||
      window.location.search?.includes("?feedItemId=")
    ) {
      window.history.replaceState("", document.title, window.location.pathname);
      setActiveFeedItemIndex(() => -1);
    }
  };

  const setPreventScrollAction = () => {
    setPreventScroll(() => true);
    setTimeout(() => {
      setPreventScroll(() => false);
    }, 500);
  };

  const feedMapIds = useMemo(
    () =>
      feedMap
        ?.filter((feedItem) => feedItem?.id !== feedId)
        ?.map((feedItem) => feedItem.id),
    [feedMap, feedId],
  );

  const downloadFeedItems = async () => {
    const lowerRange = range[0] - 20;
    const upperRange = range[1] + 20;
    const lowerBound = lowerRange > 0 ? lowerRange : 0;
    const upperBound =
      upperRange < feedMap?.length ? upperRange : feedMap?.length;
    const feedMapRange = feedMap.slice(lowerBound, upperBound);

    const idMap = feedMapRange
      .filter((item) => !item.loadedContent)
      .map((item) => item.id)
      .filter((item) => item);
    console.log("DOWNLOAD ITEMS: START", feedId, feedMapRange, idMap);

    const spanName = initialContentLoad.current
      ? actions.feedInitial(currentFeed)
      : actions.feedScroll(currentFeed);

    if (idMap?.length > 0) {
      trackAction(spanName, {
        feedId,
        "feed.itemsLoaded": idMap.length.toString(),
      });
      initialContentLoad.current = false;

      await downloadFeedItemContent(
        client,
        workspaceId,
        idMap,
        preferredLanguage,
      );
      setRenderToggle(feedId);
      console.log("DOWNLOAD ITEMS: FINISH", feedId);
      finishAction(spanName);
    }
  };

  const scrollToIndex = (index: number) => {
    if (preventScroll) {
      setTimeout(() => setPreventScroll(() => false), 250);
      return;
    }
    vListRef.current?.scrollToIndex(index, { align: "start" });
    setAllowJumpButton(() => true);
  };

  const handRangeChange = ({
    startIndex,
    endIndex,
  }: { startIndex: number; endIndex: number }) => {
    if (endIndex === feedMap?.length - 1) {
      setShowScrollToBottom(() => false);
    } else {
      setShowScrollToBottom(
        () =>
          endIndex <= feedMap?.length - 1 && feedMap?.length > countThreshold,
      );
    }
    const range = startIndex > 0 ? [startIndex - 5, endIndex + 5] : [0, 10];
    setRange(() => range);
  };

  const findFirstUnreadIndex = feedMap?.findIndex(
    (feedItem) => feedItem.unread === true,
  );

  useEffect(() => {
    downloadFeedItems();
  }, [preferredLanguage, feedId]);

  // reset values on feed change
  useEffect(() => {
    setActiveFeedItemIndex(() => -1);
    setPreventScroll(() => false);
    setShowScrollToBottom(() => false);
    setAllowJumpButton(() => false);
    setActiveIndex(() => -1);
    initialContentLoad.current = true;

    // initial feed change scroll to bottom -> Only occurs when changing feeds
    if (hasContentId) {
      const hashIndex = feedMapIds?.findIndex((id) => id === hasContentId);
      if (hashIndex > -1) {
        scrollToIndex(hashIndex);
        setActiveFeedItemIndex(() => hashIndex);
      }
      return;
    }
    if (findFirstUnreadIndex > 0) {
      scrollToIndex(findFirstUnreadIndex);
      setActiveIndex(() => findFirstUnreadIndex);
    } else {
      scrollToIndex(feedMapIds?.length - 1);
      // setTimeout(() => {
      //   scrollToIndex(feedMapIds?.length - 1);
      // }, 500);
    }
  }, [feedId]);

  // need this for search on own page
  useEffect(() => {
    if (hasContentId && feedMapIds?.length > 0) {
      const scrollPosition = feedMap?.findIndex(
        (feedItem) => feedItem.id === hasContentId,
      );
      if (scrollPosition && scrollPosition > -1) {
        scrollToIndex(scrollPosition);
        setActiveFeedItemIndex(() => scrollPosition);
      }
    }
  }, [hasContentId]);

  // remove hashId after 5 seconds
  useEffect(() => {
    if (hasContentId) {
      setTimeout(() => removeHash(), 5000);
    }
  }, [hasContentId]);

  // // scroll to latest unread item
  // useEffect(() => {
  //   if (hasContentId) {
  //     return;
  //   }
  //   if (findFirstUnreadIndex > 0) {
  //     console.log("scrolling to first unread item", findFirstUnreadIndex);
  //     scrollToIndex(findFirstUnreadIndex);
  //     setActiveIndex(() => findFirstUnreadIndex);
  //   }
  // }, [currentFeed?.id]);

  const jumpToLastItem = () => {
    scrollToIndex(feedMap?.length - 1);
    setPreventScrollAction();
  };

  const JumpToLatestMessage = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: "13rem",
          left: 0,
          display: "flex",
          zIndex: 300,
        }}
      >
        <Box
          sx={{
            maxWidth: "1300px",
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Chip
            id="jumpToLatestMessage"
            role="button"
            onClick={() => jumpToLastItem()}
            sx={{
              background: theme.palette.brand.secondary.main,
              "&:hover": { background: theme.palette.brand.secondary.light },
            }}
            label={
              <Box sx={{ display: "flex", alignItem: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowDownward role="img" sx={{ fontSize: "1rem", mr: 1 }} />
                </Box>
                <Box>Jump to latest message</Box>
              </Box>
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      {showScrollToBottom && allowJumpButton && <JumpToLatestMessage />}
      <VList
        aria-label={Locator.feed.items.list}
        data-testid={Locator.feed.testId(feedId)}
        style={{
          height: "calc(100svh - 92px)",
          paddingBottom: "220px",
          position: "relative",
          paddingRight: "6px",
          paddingLeft: "16px",
        }}
        count={feedMap?.length}
        reverse
        shift={false}
        overscan={0}
        ref={vListRef}
        id="vListRef"
        itemSize={100}
        onRangeChange={(startIndex, endIndex) => {
          handRangeChange({ startIndex, endIndex });
        }}
        onScrollEnd={async () => {
          await downloadFeedItems();
        }}
      >
        {feedMap?.map((feedItem, index) => (
          <Box
            key={`feed-item-${feedItem?.id}`}
            sx={{ maxWidth: "1300px", margin: "0 auto" }}
          >
            {feedItem.isFirstDateGroupItem ? (
              <FeedItemHeader
                isStuck={false}
                dateId={feedItem.shortDateStr}
                onClick={() => {}}
                dateLabel={feedItem.dateGroupLabel}
                isUnreadHeader={false}
                unreadCount={0}
              />
            ) : (
              <Box sx={{ height: 34 }} />
            )}

            {activeIndex === index && (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "right",
                  borderBottom: `2px solid ${theme.palette.brand.secondary.main}`,
                  fontSize: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    color: theme.palette.brand.secondary.main,
                  }}
                >
                  New
                </Typography>
              </Box>
            )}
            <FeedItem
              preventScroll={(value: boolean) => {
                setPreventScrollAction();
              }}
              setItemNotLoaded={async () => downloadFeedItems()}
              feed={currentFeed}
              item={feedItem}
              vListIndex={index}
              active={index === activeFeedItemIndex}
              highlightPodcast={index === activeFeedItemIndex}
            />
          </Box>
        ))}
      </VList>
    </Box>
  );
}
