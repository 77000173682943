import { AccountWithHFStatus, HandsFreeTypes } from "@/db/types";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import Person from "@mui/icons-material/Person";
import { Box, Tooltip, useTheme } from "@mui/material";
import { useContext } from "react";
import type { WorkspaceMember } from "@/data/workspaceMemberships";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";

const defaultSize = "md";
type SizeType = "xs" | "sm" | "md" | "xm" | "lg";

interface AvatarProps {
  size?: SizeType; // Avatar size
  accountRecord: AccountWithHFStatus; // Account ID of the user
  member?: WorkspaceMember; // Member info if there is no account id
  disableTooltip?: boolean; // Disable tooltip
}

const sizes: Array<{
  name: string;
  avatar: number;
  status: number;
  border: number;
}> = [
  { name: "xs", avatar: 16, status: 5, border: 0.75 },
  { name: "sm", avatar: 24, status: 7.5, border: 1.13 },
  { name: "md", avatar: 32, status: 10, border: 1.5 },
  { name: "xm", avatar: 40, status: 10, border: 1.5 },
  { name: "lg", avatar: 64, status: 20, border: 3 },
];

export default function AvatarWithStatusContent({
  size,
  accountRecord,
  disableTooltip,
  member,
}: AvatarProps) {
  const theme = useTheme();
  const { isWorkspaceLimitedMember, currentWorkspaceId } =
    useContext(WorkspaceContext);
  const currentStatus = accountRecord?.status ?? ("inactive" as HandsFreeTypes);
  const handsFreeAllowed = handsFreeEnabledForWorkspace(currentWorkspaceId);

  const { myAccountId } = useContext(MyAccountContext);
  const limitedMember = isWorkspaceLimitedMember();
  const foundAvatarSize =
    sizes?.find((aSize) => aSize?.name === size) ??
    sizes?.find((aSize) => aSize?.name === defaultSize);
  const avatarSize = `${foundAvatarSize.avatar}px`;
  const fontSize = `${foundAvatarSize.avatar / 2}px`;
  const statusSize = `${foundAvatarSize.status}px`;
  const statusBorder = `solid ${foundAvatarSize.border}px #222`;
  const avatarColor =
    accountRecord?.avatarColor || theme.palette.secondary.light;
  const accountName = accountRecord?.name || member?.name;
  const initial =
    accountName && accountName?.length > 0
      ? accountName[0]?.toUpperCase()
      : null;
  const showHandsFreeIndicator =
    (initial && !limitedMember) ||
    (initial && accountRecord?.id === myAccountId);
  const disabledTooltip = disableTooltip || !handsFreeAllowed || !initial;

  let handsFreeStatus = "Hands-Free is off";
  let statusColor = theme.palette.secondary.main;
  if (currentStatus === "active") {
    handsFreeStatus = "Hands-Free is on";
    statusColor = theme.palette.brand.other.green.main;
  } else if (currentStatus === "unknown") {
    handsFreeStatus = "Hands-Free is unknown";
    statusColor = theme.palette.warning.main;
  }

  return (
    <Tooltip
      title={handsFreeStatus}
      disableFocusListener={disabledTooltip}
      disableHoverListener={disabledTooltip}
      disableTouchListener={disabledTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            marginTop: "4px !important",
            fontSize: "1rem",
            fontWeight: 500,
            background: theme.palette.secondary.light,
            lineHeight: 1.25,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: avatarSize,
          height: avatarSize,
          background: !initial ? "transparent" : avatarColor,
          borderRadius: "100%",
          position: "relative",
          border: !initial ? "solid 2px #fff" : "none",
        }}
      >
        {!initial ? (
          <Person
            sx={{
              width: fontSize,
              height: fontSize,
            }}
          />
        ) : (
          <Box
            sx={{
              textAlign: "center",
              marginLeft: "1px",
              marginBottom: "-5px",
              fontWeight: 700,
              fontSize,
              lineHeight: fontSize,
              textTransform: "uppercase",
              color: theme.palette.primary.main,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              fontFamily: "monospace",
            }}
          >
            {initial}
          </Box>
        )}

        {showHandsFreeIndicator && handsFreeAllowed && (
          <Box
            sx={{
              background: statusColor,
              position: "absolute",
              right: 0,
              bottom: 0,
              borderRadius: "100%",
              width: statusSize,
              height: statusSize,
              border: statusBorder,
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
}
